
import GeneralData from "@/modules/pettyCash/components/GeneralData.vue";
import usePettyCash from "@/modules/pettyCash/composables/usePettyCash";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    setup() {
        const {clearForm, pettyCashForm, createPettyCash, catalogs} = usePettyCash()
        const sending = ref(false)

        clearForm();

        return {
            mode: 'CREATE',
            sending,
            catalogs,
            cancel: () => router.push({name: 'petty_cash'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createPettyCash(pettyCashForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'petty_cash'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
